import React, {useState} from "react";
import {faClipboard, faClipboardCheck} from "@fortawesome/pro-regular-svg-icons";
import "./inputs.css";
import {InputCopyButton} from "./input-clipboard-copy-button";
import {InputErrorIndicator} from "./input-error-indicator";
import {InputIcon} from "./input-icon";

export const InputStringValidCharSetAlphaOnly = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
export const InputStringValidCharSetUpperAlphaOnly = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
export const InputStringValidCharSetLowerAlphaOnly = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
export const InputStringValidCharSetAlphaNumeric = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

function InputString(props) {
    const [copied, setCopied] = useState(false);
    const [focused, setFocused] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const changeHandler = (e) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(e.target.value);
        }
        if (typeof props.OnError === "function") {
            const hasErrors = checkForErrors(e.target.value);
            props.OnError(hasErrors);
        }
    };

    const blurHandler = (e) => {
        setFocused(false);
        const hasErrors = checkForErrors(e.target.value);
        if (!hasErrors) {
            if (typeof props.OnBlur === "function") {
                props.OnBlur(e.target.value);
            }
        }
    };

    const focusHandler = (e) => {
        setFocused(true);
        const temp_value = e.target.value;
        e.target.value = "";
        e.target.value = temp_value;
        if (typeof props.OnFocus === "function") {
            props.OnFocus();
        }
    };

    const checkForErrors = (value) => {
        if (props.Required !== undefined && props.Required && value === "") {
            setErrorMessage("required");
            return true;
        }
        if (props.InvalidValues !== undefined && props.InvalidValues.includes(value)) {
            setErrorMessage("invalid");
            return true;
        }
        if (props.Validate !== undefined && !props.Validate(value)) { 
            setErrorMessage("invalid");
            return true;
        }
        setErrorMessage("");
        return false;
    };

    const copyToClipboard = async () => {
        setCopied(true);
        setTimeout(clearCopiedState, 5000);
        await navigator.clipboard.writeText(props.Value);
    };

    const clearCopiedState = () => {
        setCopied(false);
    };

    const getAutocomplete = () => {
        if (props.Password && !props.AutoComplete) {
            return "new-password";
        }
        return props.AutoComplete ? "on" : "off";
    };

    const filterInvalidChars = (e) => {
        // allow cut, copy and paste
        if (e.ctrlKey && (e.code === "KeyC" || e.code === "KeyV" || e.code === "KeyX")) {
            return true;
        }
        if (e.key === "ArrowRight") {
            return true;
        }
        else if (e.key === "ArrowLeft") {
            return true;
        }
        else if (e.key === "Backspace") {
            return true;
        }
        else if (e.key === "Delete") {
            return true;
        }
        else if (e.key === "Tab") {
            return true;
        }
        if (e.key === "Enter") {
            if (typeof props.OnEnter === "function")
                props.OnEnter();
            e.preventDefault();
        }
        if (props.ValidChars && !props.ValidChars.includes(e.key)) {
            e.preventDefault();
        }
        if (props.InvalidChars && props.InvalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const getClipboardIcon = () => {
        return copied ? faClipboardCheck : faClipboard;
    };

    const getContainerClass = () => {
        if (props.ContainerClass) {
            return props.ContainerClass;
        }
        if (focused) {
            return "eone-ui-input-container eone-ui-input-container-focused";
        }
        return "eone-ui-input-container";
    };
    
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    return (
        <div className={getContainerClass()} >
            <InputIcon Icon={props.Icon} DataTestId={props.DataTestId}/>
            <input
                id={props.Id}
                aria-label={props.Label || props.Placeholder || props.Value}
                type={props.Password ? "password" : "text"}
                autoFocus={props.AutoFocus}
                autoComplete={getAutocomplete()}
                readOnly={props.ReadOnly}
                className="eone-ui-input-string"
                maxLength={props.MaxLength}
                onBlur={blurHandler}
                onChange={changeHandler}
                onFocus={focusHandler}
                onKeyDown={(e) => filterInvalidChars(e)}
                placeholder={props.Placeholder}
                style={props.Style}
                title={props.Password ? null : props.Value}
                value={props.Value}
                data-testid={props.DataTestId} />
            <InputCopyButton
                Show={props.ShowCopyButton}
                Icon={getClipboardIcon()}
                Copy={copyToClipboard}
                DataTestId={props.DataTestId} />
            <InputErrorIndicator                 
                Error={errorMessage || props.ErrorMessage}
                DataTestId={props.DataTestId}/> 
        </div>
    );
}

export {InputString};